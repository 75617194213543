
import {
    FilterFormSAT,
    FormActions,
    ResponseDataStringArray as rdsa
} from "@/interfaces";
import * as types from "@/store/types";
import {
    Component,
    ModelSync,
    Prop,
    PropSync,
    Ref,
    Vue
} from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";

@Component({
    components: {
        FilterFormPanelBase: () =>
            import("@/components/layout/FilterFormPanelBase.vue"),
        CalendarRangeInput: () =>
            import("@/components/layout/CalendarRangeInputPre.vue"),
        CalendarInput: () => import("@/components/layout/CalendarInput.vue")
    },
    methods: {
        ...mapMutations({
            setFilterLoading: types.MUTATE_APP_FILTER_LOADING,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class FilterForm extends Vue {
    @ModelSync("filterForm", "change", { type: Object })
    readonly formValues!: FilterFormSAT;

    @Ref("form") readonly formActions!: FormActions;

    @PropSync("filterFormPanelShow", { type: Boolean || null })
    syncFilterFormPanelShow!: null;

    @Prop({ type: Boolean, default: true, required: false })
    readonly displayDates!: boolean;

    setFilterLoading!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    path = this.$route.path;
    userIdentifier?: number;
    companies: string[] = [];
    socios: string[] = [];
    cajas: string[] = [];
    clients: string[] = [];
    installations: string[] = [];
    formulario: any[] = [];
    loading2 = false;

    rules = {
        required: value => !!value || "Requerido",
        counter: value => value.length <= 3 || "Maximo 3 dígitos",
        days: value => !!Number(value) || "No es un número"
    };
    formRules = {
        window: [
            val =>
                /[0-9][d]{1}$|[0-9][h]{1}$|[0-9][m]{1}$|[0-9][s]{1}$/.test(
                    val
                ) || val == null
        ]
    };

    loading = false;

    mounted() {
        // this.fetchSocio();
        this.fetchFormulary();
        this.formValues.suiche = false;
        this.formValues.socio = "Seleccione un socio";
        this.formValues.company = "Seleccione una compañia";
        this.formValues.client = "Seleccione un cliente";
        this.formValues.installation = "Seleccione una instalación";
        this.formValues.caja = "Seleccione una caja";
        this.formValues.Tserie = "";
        this.formValues.Tcaja = "";
        this.formValues.Tcodigo = "";
    }

    /**
     * Methods
     */

    setFilter() {
        this.$emit("on-filter-updated");
    }

    resetFilter() {
        this.formValues.socio = "Seleccione un socio";
        this.formValues.company = "Seleccione una compañia";
        this.formValues.client = "Seleccione un cliente";
        this.formValues.installation = "Seleccione una instalación";
        this.formValues.caja = "Seleccione una caja";
        this.$emit("on-filter-reset");
    }

    newItem() {
        this.$emit("on-filter-new");
    }

    async fetchFormulary() {
        try {
            const formularyResponse = await this.$api.getFormulary<rdsa>();
            if (formularyResponse.ok) {
                this.formulario = formularyResponse.data;
                this.formulario.forEach(i => {
                    this.socios.push(i["socio"]);
                });
                const aux: string[] = [];
                this.socios.forEach(function(item) {
                    if (aux.indexOf(item) < 0) {
                        aux.push(item);
                    }
                });
                this.socios = aux;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async updateFTP() {
        this.loading2 = true;
        try {
            const response = await this.$api.updateFTP<rdsa>();
            if (response.ok) {
                this.loading2 = false;
                this.showError(
                    "El repositorio FTP se ha actualizado correctamente"
                );
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
            this.loading2 = false;
        }
    }

    // async fetchSocio() {
    //     try {
    //         const sociosResponse = await this.$api.SQLsocio<rdsa>();
    //         if (sociosResponse.ok) {
    //             this.socios = sociosResponse.data;
    //             this.formValues.socio = "Seleccione un socio";
    //             this.formValues.company = "Seleccione una compañia";
    //             this.formValues.client = "Seleccione un cliente";
    //             this.formValues.installation = "Seleccione una instalación";
    //             this.formValues.caja = "Seleccione una caja";
    //         }
    //     } catch (error) {
    //         if (error instanceof Error) {
    //             this.showError(error.message);
    //         }
    //     }
    // }

    fetchCompany() {
        const filtro = this.formulario.filter(
            item => item.socio == this.formValues.socio
        );
        const aux: string[] = [];
        const aux0 = JSON.parse(JSON.stringify(filtro));
        aux0.forEach(function(item) {
            if (aux.indexOf(item["company"]) < 0) {
                aux.push(item["company"]);
            }
        });
        this.companies = aux;
        this.formValues.company = "Seleccione una empresa";
        this.formValues.client = "Seleccione un cliente";
        this.formValues.installation = "Seleccione un instalación";
        this.formValues.caja = "Seleccione una caja";
    }
    // async fetchCompany() {
    //     try {
    //         this.companies = [];
    //         this.clients = [];
    //         this.installations = [];
    //         this.formValues.company = "Seleccione una compañia";
    //         this.formValues.client = "Seleccione un cliente";
    //         this.formValues.installation = "Seleccione una instalación";
    //         this.formValues.caja = "Seleccione una caja";
    //         const companyResponse = await this.$api.SQLcompany<rdsa>(
    //             this.formValues.socio.split(" - ")[0]
    //         );
    //         if (companyResponse.ok) {
    //             this.companies = companyResponse.data;
    //         }
    //     } catch (error) {
    //         if (error instanceof Error) {
    //             this.showError(error.message);
    //         }
    //     }
    // }
    fetchClient() {
        const filtro = this.formulario.filter(
            item => item.company == this.formValues.company
        );
        const aux: string[] = [];
        const aux0 = JSON.parse(JSON.stringify(filtro));
        aux0.forEach(function(item) {
            if (aux.indexOf(item["client"]) < 0) {
                aux.push(item["client"]);
            }
        });
        this.clients = aux;
        this.formValues.client = "Seleccione un cliente";
        this.formValues.installation = "Seleccione un instalación";
        this.formValues.caja = "Seleccione una caja";
    }

    // async fetchClient() {
    //     try {
    //         this.clients = [];
    //         this.installations = [];
    //         this.formValues.client = "Seleccione un cliente";
    //         this.formValues.installation = "Seleccione una instalación";
    //         this.formValues.caja = "Seleccione una caja";
    //         const clientResponse = await this.$api.getClientSAT<rdsa>(
    //             this.formValues.company.split(" - ")[0]
    //         );
    //         if (clientResponse.ok) {
    //             this.clients = clientResponse.data;
    //         }
    //     } catch (error) {
    //         if (error instanceof Error) {
    //             this.showError(error.message);
    //         }
    //     }
    // }    this.formValues.caja = "Seleccione una caja";

    fetchInstallation() {
        const filtro = this.formulario.filter(
            item => item.client == this.formValues.client
        );
        const aux: string[] = [];
        const aux0 = JSON.parse(JSON.stringify(filtro));
        aux0.forEach(function(item) {
            if (aux.indexOf(item["install"]) < 0) {
                aux.push(item["install"]);
            }
        });
        this.installations = aux;
        this.cajas = [];
        this.formValues.installation = "Seleccione un instalación";
        this.formValues.caja = "Seleccione una caja";
    }

    // async fetchInstallation() {
    //     try {
    //         this.installations = [];
    //         this.formValues.installation = "Seleccione una instalación";
    //         this.formValues.caja = "Seleccione una caja";
    //         const installResponse = await this.$api.getInstallationSAT<rdsa>(
    //             this.formValues.client.split(" - ")[0]
    //         );
    //         if (installResponse.ok) {
    //             this.installations = installResponse.data;
    //         }
    //     } catch (error) {
    //         if (error instanceof Error) {
    //             this.showError(error.message);
    //         }
    //     }
    // }

    fetchCaja() {
        const filtro = this.formulario.filter(
            item => item.client == this.formValues.client
        );
        const aux: string[] = [];
        const aux0 = JSON.parse(JSON.stringify(filtro));
        aux0.forEach(function(item) {
            if (aux.indexOf(item["caja"]) < 0) {
                aux.push(item["caja"]);
            }
        });
        this.cajas = aux;
        this.formValues.caja = "Seleccione una caja";
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
}
